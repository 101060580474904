exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/aboutUs/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contactUs/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-not-found-index-tsx": () => import("./../../../src/pages/pageNotFound/index.tsx" /* webpackChunkName: "component---src-pages-page-not-found-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-templates-blogs-template-tsx": () => import("./../../../src/templates/blogsTemplate.tsx" /* webpackChunkName: "component---src-templates-blogs-template-tsx" */),
  "component---src-templates-careers-template-tsx": () => import("./../../../src/templates/careersTemplate.tsx" /* webpackChunkName: "component---src-templates-careers-template-tsx" */),
  "component---src-templates-portfolio-template-tsx": () => import("./../../../src/templates/portfolioTemplate.tsx" /* webpackChunkName: "component---src-templates-portfolio-template-tsx" */),
  "component---src-templates-service-template-tsx": () => import("./../../../src/templates/serviceTemplate.tsx" /* webpackChunkName: "component---src-templates-service-template-tsx" */)
}

